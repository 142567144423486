<template>
  <div class="content-container">
    <PageTitle :title="$t('MISSION_VIDEOS.TITLE')" class="page-title">
      <template #extra-content>
        <button
          type="button"
          class="pink-button create-button"
          @click="createMissionVideo"
        >
          {{ $t('VIDEOS.CREATE') }}
        </button>
      </template>
    </PageTitle>
    <h1 class="page-section-title section-title">{{ $t('VIDEOS.OVERVIEW') }}</h1>

    <CustomTable :options="videoTableOptions"
                 :search-function="manageSearch"
                 :table-data="tableData"
                 :pagination-data="searchResults"
                 :empty="empty"
                 :empty-state-data="emptyStateData"
                 :no-results-data="noResultsData">
      <template #title="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #url="props">
        <a target="_blank" :href="props.rowData.originalVideoUrl">{{ props.rowData.originalVideoUrl }}</a>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('DELETE')"
                        :icon="require('../../../assets/icons/icn_delete.svg')"
                        custom-class="button-red"
                        class="action"
                        @click="preDeleteMissionVideo(props.rowData)"/>
        </div>
      </template>
      <template #right-side-info>
        <p><span style="font-weight: 500;">{{ totalItems }}</span> {{ $t('VIDEOS.TOTAL_VIDEOS') }}</p>
      </template>
    </CustomTable>
    <BasicModal v-if="deleteWarning"/>
    <DeleteVideoFailure v-if="deleteFailure" :video-name="toDelete.name" :slide-id="blockingSlideId"/>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { ROUTE_NAMES_CMS } from '../../../router/modules/cms'

import PageTitle from '@/components/elements/texts/PageTitle'
import ActionButton from '@/components/table/ActionButton'

import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, MISSION_VIDEOS } from '@/utils/helpers/customTable/CustomTableHelper'
import { createEmptyTableData, createNoResultsData } from '@/utils/helpers/customTable/EmptyTableHelper'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '../../../models/basicModal/BasicModalContent'
import { GET_MISSION_VIDEOS, DELETE_MISSION_VIDEO } from '../../../store/modules/cms/actions'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import DeleteVideoFailure from '../../../components/partials/missionVideos/DeleteVideoFailure'

export default {
  name: 'Video',
  components: {
    PageTitle,
    ActionButton,
    CustomTable,
    BasicModal,
    DeleteVideoFailure
  },
  data() {
    return {
      videoTableOptions: getTableOptions(MISSION_VIDEOS),
      emptyStateData: createEmptyTableData('MISSION_VIDEOS', this.createMissionVideo, require('@/assets/icons/icn_mission.svg')),
      noResultsData: createNoResultsData('MISSION_VIDEOS', require('@/assets/icons/icn_mission.svg')),
      modalController: new ModalController(),
      toDelete: '',
      blockingSlideId: null
    }
  },
  computed: {
    ...mapGetters({
      activeModal: 'getActiveBasicModal',
      searchResults: 'getMissionVideos'
    }),
    deleteWarning() { return this.activeModal === MODAL_NAMES.DELETE_MISSION_VIDEO },
    deleteFailure() { return this.activeModal === MODAL_NAMES.DELETE_MISSION_VIDEO_FAILURE },
    totalItems() { return this.searchResults.totalItems },
    tableData() { return this.searchResults.items },
    empty() { return !this.totalItems || this.totalItems === 0 }
  },
  methods: {
    createMissionVideo() {
      this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_VIDEOS_CREATE })
    },
    preDeleteMissionVideo(video) {
      this.modalController.setModal(BASIC_MODAL_CONTENT.DELETE_MISSION_VIDEO(video.name), () => this.deleteMissionVideo(video), null, null, MODAL_NAMES.DELETE_MISSION_VIDEO)
    },
    deleteMissionVideo(video) {
      this.toDelete = video
      this.$store.dispatch(DELETE_MISSION_VIDEO, video.id).then(response => {
        if (response.status === 400) {
          const description = response.data.error_description
          var numb = description.replace(/[^0-9]/g, '')
          this.blockingSlideId = parseInt(numb)
          this.modalController.setModal(BASIC_MODAL_CONTENT.DELETE_MISSION_VIDEO_FAILURE(video.name), null, null, null, MODAL_NAMES.DELETE_MISSION_VIDEO_FAILURE)
        } else {
          this.modalController.resetActiveModal()
          this.manageSearch()
        }
      })
    },
    manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      return this.$store.dispatch(GET_MISSION_VIDEOS, payload)
    }
  }

}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

</style>
